import React from 'react';
import '../index.css';
import "antd/dist/antd.css";
import moment from 'moment';

import { Button,Form,Tag } from 'antd';
import { Modal,Table,Space, Input,DatePicker,message } from 'antd';
import {
    UserAddOutlined,
    DeleteOutlined,
    EditOutlined,
    SearchOutlined,
    AuditOutlined,
} from '@ant-design/icons';

import baseurl from '../public/baseurl';
import {request} from '../public/util/helper';
// import { localStorageclear } from '../public/util/localStorageutil';



class employees extends React.Component {
    constructor(props){
        super();
        this.state={
            data:[],
            visible:false,
            editvisible:false,
            confirmLoading:false,
            title:'',
            handleOkf:null,
            modalconment:"",
            newname:"",
            newhireDate:"",
            newsection:"",
            querysection:"",
            queryname:"",
            editdate:{},
          };
        this.adduser=this.adduser.bind(this);
        this.adduserhandleOk=this.adduserhandleOk.bind(this);
        this.inittable=this.inittable.bind(this);
        this.deleteuserhandleOk=this.deleteuserhandleOk.bind(this);
        this.edituserhandleOk=this.edituserhandleOk.bind(this);
    }
    componentDidMount() {
        this.inittable()
    }
    inittable(){
        request({
            url:baseurl.employees.queryZhjEmployeesAll,
            method:'post',
            props:this.props,
            data:{
                section:this.state.querysection,
                name:this.state.queryname
            },
          }).then((res)=>{
            if(res && res.data){
                this.setState({
                    data:res.data
                })
            }
          });
    }
    edituser(row){
        this.setState({
            editdate:row,
            editvisible:true,
        })
        
    }
    edituserhandleOk(){
        if(this.state.editdate.name==="" || this.state.editdate.hireDate==="" || this.state.editdate.section===""){
            message.error("请填写姓名、部门、入职时间");
            return;
        }
        request({
            url:baseurl.employees.updateZhjEmployees,
            method:'post',
            props:this.props,
            data:this.state.editdate,
          }).then((res)=>{
            this.inittable()
            this.setState({
                editvisible:false
            });
            
          });
    }
    deleteuser(id,name){
        this.setState({
            handleOkf:()=>{
              this.deleteuserhandleOk(id)
            },
            visible:true,
            title:'删除',
            modalconment:<div>你确定要删除人员 <strong>{name}</strong>吗？</div>
          });
    }
    deleteuserhandleOk(id){
        request({
            url:baseurl.employees.deleteZhjEmployees+"?id="+id,
            method:'post',
            props:this.props,
            data:{},
          }).then((res)=>{
            this.inittable()
            this.setState({
                visible:false
            });
            
          });
    }
    adduser(){
        this.setState(
            {
                handleOkf:()=>{
                this.adduserhandleOk()
                },
                visible:true,
                title:'新增',
                modalconment: 
                    <div>
                        <Form>
                            <Form.Item label="人员姓名">
                            <Input
                                prefix={<UserAddOutlined className="site-form-item-icon" />}
                                onChange={({ target: { value }})=>{
                                            this.setState({
                                                newname:value
                                            });
                                        }}
                            />
                            </Form.Item>
                            <Form.Item label="部门">
                            <Input
                                prefix={<AuditOutlined  className="site-form-item-icon" />}
                                onChange={({ target: { value }})=>{
                                            this.setState({
                                                newsection:value
                                            });
                                        }}
                            />
                            </Form.Item>
                            <Form.Item label="入职日期">
                            <DatePicker style={{ width: '100%' }} format={'YYYY-MM-DD'} onChange={(date, dateString)=>{
                                            this.setState({
                                                newhireDate:dateString
                                            });
                                        }} />
                            </Form.Item>
                        </Form>
                    </div>
            }
        );
    }
    adduserhandleOk(){
        if(this.state.newname==="" || this.state.newhireDate==="" || this.state.newsection===""){
            message.error("请填写姓名、部门、入职时间");
            return;
        }
        request({
            url:baseurl.employees.addZhjEmployees,
            method:'post',
            props:this.props,
            data:{
              "name":this.state.newname,
              "hireDate":this.state.newhireDate,
              "section":this.state.newsection
            },
          }).then((res)=>{
            this.inittable()
            this.setState({
                visible:false
            });
          });
    }
   
    render() {
        const columns = [
            {
                title: '姓名',
            //   width:'40%',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: '部门',
            //   width:'40%',
                dataIndex: 'section',
                key: 'section',
            },
            {
                title: '入职日期',
            //   width:'40%',
                dataIndex: 'hireDate',
                key: 'hireDate',
                sorter: (rowa, rowb) => {
                    let date1 = new Date(rowa.hireDate);
                    let date2 = new Date(rowb.hireDate);

                    if (date1 < date2) {
                        return -1;
                    } else if (date1 > date2) {
                        return 1;
                    } else {
                        return 0;
                    }
                    },
            },
            {
                title: '首月消息提醒日期',
                //   width:'40%',
                dataIndex: 'firstMonthReminderDate',
                key: 'firstMonthReminderDate',
            },
            {
                title: '首月消息状态',
                //   width:'40%',
                dataIndex: 'firstMonthReminderSent',
                key: 'firstMonthReminderSent',
                render: text => <Tag color={text==='0'?'gold':'green'}>{text==='0'?'未发送':'已发送'}</Tag>,
            },
            {
                title: '二月消息提醒日期',
                //   width:'40%',
                dataIndex: 'secondMonthReminderDate',
                key: 'secondMonthReminderDate',
            },
            {
                title: '二月消息状态',
                //   width:'40%',
                dataIndex: 'secondMonthReminderSent',
                key: 'secondMonthReminderSent',
                render: text => <Tag color={text==='0'?'gold':'green'}>{text==='0'?'未发送':'已发送'}</Tag>,
            },
            {
                title: '三月消息提醒日期',
                //   width:'40%',
                dataIndex: 'thirdMonthReminderDate',
                key: 'thirdMonthReminderDate',
            },
            {
                title: '三月消息状态',
                //   width:'40%',
                dataIndex: 'thirdMonthReminderSent',
                key: 'thirdMonthReminderSent',
                render: text => <Tag color={text==='0'?'gold':'green'}>{text==='0'?'未发送':'已发送'}</Tag>,
            },
            {
                title: '六月消息提醒日期',
                //   width:'40%',
                dataIndex: 'sixthMonthReminderDate',
                key: 'sixthMonthReminderDate',
            },
            {
                title: '六月消息状态',
                //   width:'40%',
                dataIndex: 'sixthMonthReminderSent',
                key: 'sixthMonthReminderSent',
                render: text => <Tag color={text==='0'?'gold':'green'}>{text==='0'?'未发送':'已发送'}</Tag>,
            },
            
            {
                title: '操作',
                key: 'action',
                render: (record) => {
                return(
                    <Space size="middle">
                        <Button type="primary" icon={<EditOutlined />} onClick={()=>this.edituser(record)} size="small" >编辑</Button>
                        <Button type="primary" icon={<DeleteOutlined />} onClick={()=>this.deleteuser(record.id,record.name)} size="small" >删除</Button>
                    </Space>
                );
                
                },  
            },
        ];

        return(<div>
        <Table
            pagination={false}
            columns={columns}
            dataSource={this.state.data}
            bordered
            title={() =>{
                return (
                    <div>
                        <Space>
                            <Form.Item label="部门">
                                <Input  allowClear placeholder="输入部门" 
                                onChange={({ target: { value }})=>{
                                            this.setState({
                                                querysection:value
                                            });
                                        }}/>
                            </Form.Item>
                            <Form.Item label="姓名">
                                <Input allowClear placeholder="输入姓名" 
                                onChange={({ target: { value }})=>{
                                    this.setState({
                                        queryname:value
                                    });
                                }}/>
                            </Form.Item>
                        </Space>
                        <br></br>
                        <Space>
                            <Button type="primary" icon={<SearchOutlined />} size="small" shape="round" onClick={()=>this.inittable()}>查询</Button>
                            <Button type="primary" icon={<UserAddOutlined />} size="small" shape="round" onClick={()=>this.adduser()}>新增</Button>
                        </Space>
                    </div>
                    
                )   
                
            }}
        />
        <Modal
            title={this.state.title}
            open={this.state.visible}
            onOk={this.state.handleOkf}
            confirmLoading={this.state.confirmLoading}
            onCancel={()=>{
            this.setState({
                visible:false
            });
            }}
        >
            {this.state.modalconment}
        </Modal>
        <Modal
            title="编辑"
            open={this.state.editvisible}
            onOk={()=>this.edituserhandleOk()}
            confirmLoading={this.state.confirmLoading}
            onCancel={()=>{
            this.setState({
                editvisible:false
            });
            }}
        >
            <Form>
                <Form.Item label="人员姓名">
                <Input
                    value={this.state.editdate.name}
                    prefix={<UserAddOutlined className="site-form-item-icon" />}
                    onChange={({ target: { value }})=>{
                                this.setState({
                                    editdate:{
                                        ...this.state.editdate,
                                        name:value
                                    }
                                });
                            }}
                />
                </Form.Item>
                <Form.Item label="部门">
                <Input
                    value={this.state.editdate.section}
                    prefix={<AuditOutlined className="site-form-item-icon" />}
                    onChange={({ target: { value }})=>{
                                this.setState({
                                    editdate:{
                                        ...this.state.editdate,
                                        section:value
                                    }
                                });
                            }}
                />
                </Form.Item>
                <Form.Item label="入职日期">
                <DatePicker value={moment(this.state.editdate.hireDate, 'YYYY-MM-DD')} style={{ width: '100%' }} format={'YYYY-MM-DD'} onChange={(date, dateString)=>{
                                this.setState({
                                    editdate:{
                                        ...this.state.editdate,
                                        hireDate:dateString
                                    }
                                });
                            }} />
                </Form.Item>
                <Form.Item label="首月提醒日期">
                <DatePicker value={moment(this.state.editdate.firstMonthReminderDate, 'YYYY-MM-DD')} style={{ width: '100%' }} format={'YYYY-MM-DD'} onChange={(date, dateString)=>{
                                this.setState({
                                    editdate:{
                                        ...this.state.editdate,
                                        firstMonthReminderDate:dateString
                                    }
                                });
                            }} />
                </Form.Item>
                <Form.Item label="二月提醒日期">
                <DatePicker value={moment(this.state.editdate.secondMonthReminderDate, 'YYYY-MM-DD')} style={{ width: '100%' }} format={'YYYY-MM-DD'} onChange={(date, dateString)=>{
                                this.setState({
                                    editdate:{
                                        ...this.state.editdate,
                                        secondMonthReminderDate:dateString
                                    }
                                });
                            }} />
                </Form.Item>
                <Form.Item label="三月提醒日期">
                <DatePicker value={moment(this.state.editdate.thirdMonthReminderDate, 'YYYY-MM-DD')} style={{ width: '100%' }} format={'YYYY-MM-DD'} onChange={(date, dateString)=>{
                                this.setState({
                                    editdate:{
                                        ...this.state.editdate,
                                        thirdMonthReminderDate:dateString
                                    }
                                });
                            }} />
                </Form.Item>
                <Form.Item label="六月提醒日期">
                <DatePicker value={moment(this.state.editdate.sixthMonthReminderDate, 'YYYY-MM-DD')} style={{ width: '100%' }} format={'YYYY-MM-DD'} onChange={(date, dateString)=>{
                                this.setState({
                                    editdate:{
                                        ...this.state.editdate,
                                        sixthMonthReminderDate:dateString
                                    }
                                });
                            }} />
                </Form.Item>
            </Form>
        </Modal>
        </div>);
    }

}
export default employees;