import React from 'react';
import ReactDOM from 'react-dom';
// import { HashRouter, Route,Switch } from 'react-router-dom';
import SiderDemo from './main/SiderDemo';
import admin from './main/admin';
import employees from './main/employees';
import Login from './login/logon';
// import mobilelogin from './mobile/login/login';
import mobilemain from './mobile/main/main';
import Share from './main/share';
import NOpage from './public/model/404';
// import userguanli from './public/model/userguanli';
// import text from './text/text1'
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from "react-router-dom";

browserRedirect();
function browserRedirect() {
  var sUserAgent = navigator.userAgent.toLowerCase();
  var bIsIpad = sUserAgent.match(/ipad/i)!== null;
  var bIsIphoneOs = sUserAgent.match(/iphone os/i) !== null;
  var bIsMidp = sUserAgent.match(/midp/i)!== null;
  var bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) !== null;
  var bIsUc = sUserAgent.match(/ucweb/i) !== null;
  var bIsAndroid = sUserAgent.match(/android/i) !== null;
  var bIsCE = sUserAgent.match(/windows ce/i) !== null;
  var bIsWM = sUserAgent.match(/windows mobile/i) !== null;
  if (!(bIsIpad || bIsIphoneOs || bIsMidp || bIsUc7 || bIsUc || bIsAndroid || bIsCE || bIsWM) ){
    ReactDOM.render(
      <Router>
        <Switch>
          <Route exact path="/" component={Login}/>
          <Route exact path="/main" component={SiderDemo}/>
          <Route exact path="/admin" component={admin}/>
          <Route exact path="/employees" component={employees}/>
          <Route exact path="/quickurl/:key" component={Share}/>
          <Route exact path="/404" component={NOpage}/>
          <Route path="*" component={NOpage}/>
          {/* <Route path="/text" component={text}/> */}
        </Switch>
      </Router>, 
    document.getElementById("root"))
  }else
  {
    ReactDOM.render(
      <Router>
        <Switch>
          <Route exact path="/" component={Login}/>
          <Route exact path="/main" component={mobilemain}/>
          <Route exact path="/quickurl/:key" component={Share}/>
          <Route exact path="/404" component={NOpage}/>
          <Route path="*" component={NOpage}/>
          {/* <Route path="/admin" component={admin}/> */}
          {/* <Route path="/text" component={text}/> */}
        </Switch>
      </Router>, 
    document.getElementById("root"))
  }
}